<template>
  <div>
     <div class="app-content content" style="margin: 0; padding: 30px;">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-cover">
                    <div class="auth-inner row m-0">
                        <div class="d-none d-lg-flex col-lg-6 align-items-center p-5">
                            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" src="app-assets/images/pages/forgot-password-v2.svg" alt="Forgot password V2" /></div>
                        </div>
                        <!-- /Left Text-->
                        <!-- Forgot password-->
                        <div class="d-flex col-lg-6 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                              <div style="margin-left: -15px;">
                                  <a class="nav-link py-lg-2" href="/">
                                      <img src="app-assets/images/brand-logo.png" style="height: 45px;">
                                   </a>
                                </div>
                                <h2 class="card-title fw-bold mb-1">Forgot Password? 🔒</h2>
                                <p class="card-text mb-2">Enter your email and we'll send you instructions to reset your password</p>
                                <form class="auth-forgot-password-form mt-2" action="auth-reset-password-cover.html" method="POST" @submit.prevent="forgetPassword()">
                                    <div class="mb-1">
                                        <label class="form-label" for="forgot-password-email">Email</label>
                                        <input class="form-control" id="forgot-password-email" v-model="formData.email" type="text" name="forgot-password-email" placeholder="Email (as user id)" aria-describedby="forgot-password-email" autofocus="" tabindex="1" />
                                    </div>
                                    <button class="btn btn-primary w-100" tabindex="2">Send reset link</button>
                                </form>
                                <p class="text-center mt-2"><router-link to="/login"><i data-feather="chevron-left"></i> Back to login</router-link></p>
                            </div>
                        </div>
                        <!-- /Forgot password-->
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import handleForgetPassword from '@/services/modules/forgetPassword'
import { inject } from 'vue'
export default {
  name: 'ForgetPassword',
  components: {
    
  },
  data: () => ({
      errMessage:'',
      defaultButton:true,
      formData: {
        email: null,
      },
  }),
  methods:{
    async forgetPassword() {
      try {
        this.loading = true
        let res = await this.setForgetPassword(this.formData);
        if(!res.status) {
          this.showError(res.message)
        }
        if(res.status) {
          this.showSuccess(res.message)
          //  window.location.href = '/profile';
          setTimeout(() => {
            this.$router.push({name: 'Login'})
          }, 1000)
        }
      } catch (err) {
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
          this.showError(err.response.message)
        }
      } finally {
         this.loading = false
      }
    },
  },
  setup() {
    const showError =  inject('showError');
    const showSuccess =  inject('showSuccess');
    // const store = useStore()  

    const { 
      // loginUser, 
      loading,
      setForgetPassword
      // setUserInLocalStorage,
      // setTokenInLocalStorage
    } = handleForgetPassword()

    return {
      // setUser: () => store.commit('user/setUser'),
      // user: computed(() => store.state.user.user),
      // loginUser,
      loading,
      showError,
      showSuccess,
      setForgetPassword
      // setUserInLocalStorage,
      // setTokenInLocalStorage
    }
  }
}
</script>

<style scoped>
  .contnet{
    margin: 0 !important;
  }
</style>
